body, html{

}

html{
    height: 100%;
    width: 100%;
    position: relative;
    display: block;
    color: #fff;
}

body{
    background-color: #21262b;
}

.m-full-height{
    height: 100%;
}

.m-no-margin{
    margin: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $source-sans;
    line-height: 1;
    color: #fff;
    font-weight: 200;
}

h3{
    font-size: 1.5rem;
}

// inputs
input[type*=checkbox]{

    display: none;

    +label{
        line-height: 20px; cursor: pointer;
        span{
            display:inline-block;
            width: 20px;
            height: 20px;
            margin: 0 20px 0 0;
            vertical-align: middle;
            cursor: pointer;
            border: 1px solid rgba(255,255,255,0.2);
            float: left;
            box-sizing: border-box;
        }
    }

    &:checked{
        +label span{
            background: url('/assets/img/checked.svg') center center no-repeat;
        }
    }

}

input:not([type*=checkbox]):not([type*=radio]), textarea, select{

    display: block;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    outline: none;
    font-size: 1rem;
    color: #fff;
    padding: 0.75rem;
    line-height: 1;
    transition: all 100ms linear;
    letter-spacing:  0.1rem;
    background-color: #2a2d32;
    outline: none;
    box-shadow: none;
    border-radius: 1px;
    font-family: $source-sans;
    font-weight: 400;

    &:active, &:focus{

    }

    &.ng-invalid.ng-dirty{
        border-color: #a94442;
    }

    &:focus{
        color: #fff;
        background-color: #2a2d32;
        outline: none;
    }

}

textarea{
    min-height: 8rem;
}

// form controls
.form-control{

    font-family: $source-sans;
    border: 1px solid transparent;
    box-shadow: none;
    border-radius: 1px;
    background-color: #2a2d32;
    color: #fff;
    letter-spacing: 0.1rem;
    padding: 0.75rem;
    height: auto;
    line-height: 1;
    font-weight: 400;

    &::-webkit-input-placeholder{
        color:#d5d5d5;
    }

    &::-moz-placeholder {
        color:#d5d5d5;
    }

    &:-moz-placeholder {
        color:#d5d5d5;
    }

    &:-ms-input-placeholder{
        color:#d5d5d5;
    }

    &:focus{
        color: #fff;
        background-color: #2a2d32;
        outline: none;
    }


}

label{
    color: inherit;
}

button, .btn{
    @include ms(-2);
    font-weight: 500;
    text-transform: uppercase;
    font-family: $source-sans;
    color: #fff;
    letter-spacing: 0.1rem;
    border: 1px solid #016acc;
    border: 1px solid #35bfee;
    border-radius: 1px;
    padding: 0.75rem 2rem;
    cursor: pointer;
    // http://colorzilla.com/gradient-editor/#4bdcf1+0,199ad4+100
    background: #4bdcf1;
    background: -moz-linear-gradient(top,  #4bdcf1 0%, #199ad4 100%);
    background: -webkit-linear-gradient(top,  #4bdcf1 0%,#199ad4 100%);
    background: linear-gradient(to bottom,  #4bdcf1 0%,#199ad4 100%);
    outline: none;
    box-shadow: none;
    transition: none;
    &:hover, &:focus, &:active{
        outline: none;
    }
    &:hover, &:focus{
        box-shadow: inset 0px -20px 20px rgba(25, 154, 212, 0.25);
    }
    &:active{
        box-shadow: inset 0px -40px 20px rgba(25, 154, 212, 0.25);
    }
    &.m-secondary{
        border-color: #DF952D;
        // http://colorzilla.com/gradient-editor/#eec546+0,d5791c+100
        background: #eec546;
        background: -moz-linear-gradient(top,  #eec546 0%, #d5791c 100%);
        background: -webkit-linear-gradient(top,  #eec546 0%,#d5791c 100%);
        background: linear-gradient(to bottom,  #eec546 0%,#d5791c 100%);
        &:hover, &:focus{
            box-shadow: inset 0px -20px 20px #d5791c;
        }
        &:active{
            box-shadow: inset 0px -40px 20px #d5791c;
        }
    }
}
