// media query variables based on bootstrap 4 values
//
// xs: <=575
// sm: 576-767
// md: 768-991
// lg: 992-1199
// xl: 1200-1499
// xxl: >=1500
//
// xs - portrait phones
$xs: 'only screen and (min-width: 0px) and (max-width: 575px)';
$xs-min: 'only screen and (min-width: 0px)';
$xs-max: 'only screen and (max-width: 575px)';

// sm - landscape phones
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';
$sm-min: 'only screen and (min-width: 576px)';
$sm-max: 'only screen and (max-width: 767px)';

// md - tablets
$md: 'only screen and (min-width: 768px) and (max-width: 991px)';
$md-min: 'only screen and (min-width: 768px)';
$md-max: 'only screen and (max-width: 991px)';

// lg - desktops
$lg: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$lg-min: 'only screen and (min-width: 992px)';
$lg-max: 'only screen and (max-width: 1199px)';

// xl - large desktops
$xl: 'only screen and (min-width: 1200px) and (max-width: 1499px)';
$xl-min: 'only screen and (min-width: 1200px)';
$xl-max: 'only screen and (max-width: 1499px)';

// xxl - extra large desktops
$xxl: 'only screen and (min-width: 1500px) and (max-width: 1920px)';
$xxl-min: 'only screen and (min-width: 1500px)';
$xxl-max: 'only screen and (max-width: 1920px)';

// high density devices
$highdensity:
    'only screen and (-webkit-min-device-pixel-ratio: 1.5)',
    'only screen and (min--moz-device-pixel-ratio: 1.5)',
    'only screen and (-o-min-device-pixel-ratio: 3/2)',
    'only screen and (min-device-pixel-ratio: 1.5)';

// fonts
$source-sans: 'Source Sans Pro', sans-serif;

// colors
$blue: #0084ff;
