@import url(//fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,700);
html {
  font-size: 16px;
}
@media only screen and (min-width: 576px) {
  html {
    font-size: 17px;
  }
}
@media only screen and (min-width: 768px) {
  html {
    font-size: 18px;
  }
}
@media only screen and (min-width: 992px) {
  html {
    font-size: 19px;
  }
}
@media only screen and (min-width: 1200px) {
  html {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1500px) {
  html {
    font-size: 21px;
  }
}

body {
  line-height: 1;
}

p {
  font-size: 1rem;
  line-height: 1.2;
  margin: 0.5rem 0;
}

.m10 {
  font-size: 5.159780352rem;
}

.m9 {
  font-size: 4.29981696rem;
}

.m8 {
  font-size: 3.5831808rem;
}

.m7 {
  font-size: 2.985984rem;
}

.m6 {
  font-size: 2.48832rem;
}

.m5 {
  font-size: 2.0736rem;
}

.m4 {
  font-size: 1.728rem;
}

.m3 {
  font-size: 1.44rem;
}

.m2 {
  font-size: 1.2rem;
}

.m1 {
  font-size: 1rem;
}

.m0 {
  font-size: 0.8333333333rem;
}

html {
  height: 100%;
  width: 100%;
  position: relative;
  display: block;
  color: #fff;
}

body {
  background-color: #21262b;
}

.m-full-height {
  height: 100%;
}

.m-no-margin {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1;
  color: #fff;
  font-weight: 200;
}

h3 {
  font-size: 1.5rem;
}

input[type*=checkbox] {
  display: none;
}
input[type*=checkbox] + label {
  line-height: 20px;
  cursor: pointer;
}
input[type*=checkbox] + label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 20px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.2);
  float: left;
  box-sizing: border-box;
}
input[type*=checkbox]:checked + label span {
  background: url("/assets/img/checked.svg") center center no-repeat;
}

input:not([type*=checkbox]):not([type*=radio]), textarea, select {
  display: block;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  outline: none;
  font-size: 1rem;
  color: #fff;
  padding: 0.75rem;
  line-height: 1;
  transition: all 100ms linear;
  letter-spacing: 0.1rem;
  background-color: #2a2d32;
  outline: none;
  box-shadow: none;
  border-radius: 1px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}
input:not([type*=checkbox]):not([type*=radio]).ng-invalid.ng-dirty, textarea.ng-invalid.ng-dirty, select.ng-invalid.ng-dirty {
  border-color: #a94442;
}
input:not([type*=checkbox]):not([type*=radio]):focus, textarea:focus, select:focus {
  color: #fff;
  background-color: #2a2d32;
  outline: none;
}

textarea {
  min-height: 8rem;
}

.form-control {
  font-family: "Source Sans Pro", sans-serif;
  border: 1px solid transparent;
  box-shadow: none;
  border-radius: 1px;
  background-color: #2a2d32;
  color: #fff;
  letter-spacing: 0.1rem;
  padding: 0.75rem;
  height: auto;
  line-height: 1;
  font-weight: 400;
}
.form-control::-webkit-input-placeholder {
  color: #d5d5d5;
}
.form-control::-moz-placeholder {
  color: #d5d5d5;
}
.form-control:-moz-placeholder {
  color: #d5d5d5;
}
.form-control:-ms-input-placeholder {
  color: #d5d5d5;
}
.form-control:focus {
  color: #fff;
  background-color: #2a2d32;
  outline: none;
}

label {
  color: inherit;
}

button, .btn {
  font-size: 0.6944444444rem;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  letter-spacing: 0.1rem;
  border: 1px solid #016acc;
  border: 1px solid #35bfee;
  border-radius: 1px;
  padding: 0.75rem 2rem;
  cursor: pointer;
  background: #4bdcf1;
  background: -moz-linear-gradient(top, #4bdcf1 0%, #199ad4 100%);
  background: -webkit-linear-gradient(top, #4bdcf1 0%, #199ad4 100%);
  background: linear-gradient(to bottom, #4bdcf1 0%, #199ad4 100%);
  outline: none;
  box-shadow: none;
  transition: none;
}
button:hover, button:focus, button:active, .btn:hover, .btn:focus, .btn:active {
  outline: none;
}
button:hover, button:focus, .btn:hover, .btn:focus {
  box-shadow: inset 0px -20px 20px rgba(25, 154, 212, 0.25);
}
button:active, .btn:active {
  box-shadow: inset 0px -40px 20px rgba(25, 154, 212, 0.25);
}
button.m-secondary, .btn.m-secondary {
  border-color: #DF952D;
  background: #eec546;
  background: -moz-linear-gradient(top, #eec546 0%, #d5791c 100%);
  background: -webkit-linear-gradient(top, #eec546 0%, #d5791c 100%);
  background: linear-gradient(to bottom, #eec546 0%, #d5791c 100%);
}
button.m-secondary:hover, button.m-secondary:focus, .btn.m-secondary:hover, .btn.m-secondary:focus {
  box-shadow: inset 0px -20px 20px #d5791c;
}
button.m-secondary:active, .btn.m-secondary:active {
  box-shadow: inset 0px -40px 20px #d5791c;
}

.blue {
  color: #0084ff;
}

.content {
  display: flex;
  width: 100%;
  min-height: 100%;
  position: absolute;
  align-items: center;
  align-content: center;
}

[coming-soon] {
  padding: 3rem 8rem;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: auto;
  position: relative;
  z-index: 3;
  width: 100%;
}
@media only screen and (min-width: 1200px) {
  [coming-soon] {
    background-image: url("/assets/img/laptop.png");
  }
}
@media only screen and (max-width: 767px) {
  [coming-soon] {
    padding: 3rem 1.5rem;
    text-align: center;
  }
}

.logo {
  background-image: url("/assets/img/logo.png");
  background-repeat: no-repeat;
  background-position: center center;
  width: 162px;
  height: 33px;
}
@media only screen and (max-width: 767px) {
  .logo {
    margin: 0 auto;
  }
}

.heading {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 200;
  color: #fff;
  margin: 2rem 0;
  letter-spacing: 0.2rem;
  max-width: 950px;
  line-height: 1;
}

.sub-heading {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  color: #636871;
  font-size: 1rem;
  line-height: 1;
}

.reach-out {
  margin-top: 3rem;
}

.hr {
  display: none;
  height: 1px;
  width: 100%;
  background-color: #6a6c6e;
  margin: 200px 0 0;
  border: none;
}

[polygons] {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
[polygons] canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
[polygons] #controls {
  position: absolute;
  right: 0;
  z-index: 99;
  top: 0;
}

.overlay {
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2;
}
.overlay.noise {
  background-image: url("/assets/img/noise.png");
  background-repeat: repeat;
  background-position: top left;
  opacity: 0.05;
}
.overlay.vignette {
  display: none;
  background-image: -webkit-radial-gradient(50% 50%, ellipse, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.25) 100%);
  background-image: radial-gradient(50% 50%, ellipse, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.25) 100%);
}

[request-form] {
  z-index: 2;
  max-width: 75%;
  padding: 2rem;
  background-color: rgba(35, 38, 42, 0.5);
  position: relative;
  margin: 2rem auto;
  font-family: "Source Sans Pro";
  font-weight: 200;
  letter-spacing: 0.1rem;
}
[request-form] .help {
  color: #d5d5d5;
  font-size: 0.75rem;
  float: left;
  line-height: 1;
}
[request-form] button {
  float: right;
}
[request-form] input.validate-form {
  display: none;
}
[request-form] label {
  display: none;
}
[request-form] .close {
  position: absolute;
  top: calc(1rem - 10px);
  right: calc(1rem - 10px);
  color: #fff;
  font-size: 20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  line-height: 1;
  text-align: center;
}
[request-form].is-loading {
  opacity: 0.5;
}
@media only screen and (max-width: 991px) {
  [request-form] {
    max-width: 90%;
  }
}

@keyframes load {
  from {
    background-position: top center;
  }
  to {
    background-position: 0 -1938px;
  }
}
.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(35, 38, 42, 0.5);
  display: block;
}
.loading .loader {
  background-image: url("/assets/img/loader-sprite.png");
  background-repeat: no-repeat;
  background-position: top center;
  width: 102px;
  height: 102px;
  animation: load 0.8s steps(19) infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -51px 0 0 -51px;
  display: inline-block;
  pointer-events: none;
}

.thank-you, .error {
  text-align: center;
  vertical-align: middle;
  font-family: "Source Sans Pro";
  font-weight: 200;
  letter-spacing: 0.1em;
  padding: 0 10%;
}
.thank-you h1, .error h1 {
  margin: 0;
  color: #0084ff;
  font-weight: inherit;
  font-size: 2.5rem;
  font-family: "Source Sans Pro";
  line-height: 1;
}
.thank-you h3, .error h3 {
  margin: 15px 0 0;
  color: #d5d5d5;
  font-weight: inherit;
  font-size: 1.5rem;
  font-family: "Source Sans Pro";
  line-height: 1;
}

.error-message {
  z-index: 9;
  margin: 0 auto;
  text-align: center;
}
.error-message pre {
  text-align: left;
  color: #fff;
  overflow: hidden;
}