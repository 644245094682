// set the base font size for rem use
// scales between 16-21 for all breakpoints
html{

    font-size: 16px;

    @media #{$sm-min}{
        font-size: 17px;
    }

    @media #{$md-min}{
        font-size: 18px;
    }

    @media #{$lg-min}{
        font-size: 19px;
    }

    @media #{$xl-min}{
        font-size: 20px;
    }

    @media #{$xxl-min}{
        font-size: 21px;
    }

}

body{
    line-height: 1;
}

// default paragraph text
p{
    @include ms(0);
    line-height: 1.2;
    margin: 0.5rem 0;
}

// mod scale modifier classes
.m10{
    @include ms(9);
}

.m9{
    @include ms(8);
}

.m8{
    @include ms(7);
}

.m7{
    @include ms(6);
}

.m6{
    @include ms(5);
}

.m5{
    @include ms(4);
}

.m4{
    @include ms(3);
}

.m3{
    @include ms(2);
}

.m2{
    @include ms(1);
}

.m1{
    @include ms(0);
}

.m0{
    @include ms(-1);
}
