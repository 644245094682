.blue{
    color:$blue;
}

.content{
    display: flex;
    width: 100%;
    min-height: 100%;
    position: absolute;
    align-items: center;
    align-content: center;
}

[coming-soon]{

    padding: 3rem 8rem;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: auto;
    position: relative;
    z-index: 3;
    width: 100%;

    @media #{$xl-min}{
        background-image: url('/assets/img/laptop.png');
    }
    @media #{$sm-max}{
        padding: 3rem 1.5rem;
        text-align: center;
    }

}

.logo{
    background-image: url('/assets/img/logo.png');
    background-repeat: no-repeat;
    background-position: center center;
    width: 162px;
    height: 33px;
    @media #{$sm-max}{
        margin:0 auto;
    }
}

.heading{
    font-family: $source-sans;
    font-weight: 200;
    color: #fff;
    margin: 2rem 0;
    letter-spacing: 0.2rem;
    max-width: 950px;
    line-height: 1;
}

.sub-heading{
    font-family: $source-sans;
    font-weight: 400;
    color: #636871;
    font-size: 1rem;
    line-height:1;
}

.reach-out{
    margin-top: 3rem;
}

.hr{
    display: none;
    height: 1px;
    width: 100%;
    background-color: #6a6c6e;
    margin: 200px 0 0;
    border: none;
}

[polygons]{

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    canvas{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    #controls{
        position: absolute;
        right: 0;
        z-index: 99;
        top: 0;
    }

}

.overlay{

    pointer-events: none;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2;

    &.noise{
        background-image: url('/assets/img/noise.png');
        background-repeat: repeat;
        background-position: top left;
        opacity: 0.05;
    }

    &.vignette{
        display: none;
        background-image: -webkit-radial-gradient(50% 50%, ellipse, rgba(0,0,0,0) 40%, rgba(0,0,0,0.25) 100%);
        background-image: radial-gradient(50% 50%, ellipse, rgba(0,0,0,0) 40%, rgba(0,0,0,0.25) 100%);
    }

}

[request-form]{

    z-index: 2;
    max-width: 75%;
    padding: 2rem;
    background-color: rgba(35,38,42,.5);
    position: relative;
    margin: 2rem auto;
    font-family: 'Source Sans Pro';
    font-weight: 200;
    letter-spacing: 0.1rem;

    .help{
        color: #d5d5d5;
        font-size: 0.75rem;
        float: left;
        line-height: 1;
    }

    button{
        float:right;
    }

    input.validate-form{
        display: none;
    }

    label{
        display:none;
    }

    .close{
        position: absolute;
        top: calc(1rem - 10px);
        right: calc(1rem - 10px);
        color: #fff;
        font-size: 20px; height: 20px;
        width: 20px;
        cursor: pointer;
        line-height: 1;
        text-align: center;
    }

    &.is-loading{
        opacity: 0.5;
    }

    @media #{$md-max}{
        max-width: 90%;
    }

}

@keyframes load{
    from{
        background-position: top center
    }
    to{
        background-position: 0 -1938px;
    }
}

//http://spiffygif.com/?format=png&color=0084ff&corners=0&lines=16&length=20&radius=20&width=10
.loading{

    position :absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: rgba(35,38,42,0.5);
    display: block;

    .loader{
        background-image: url('/assets/img/loader-sprite.png');
        background-repeat: no-repeat;
        background-position: top center;
        width: 102px;
        height: 102px;
        animation: load 0.8s steps(19) infinite;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -51px 0 0 -51px;
        display: inline-block;
        pointer-events: none;
    }

}

.thank-you, .error{

    // background-color: rgba(35,38,42,0.5);
    text-align: center;
    vertical-align: middle;
    font-family: 'Source Sans Pro';
    font-weight: 200;
    letter-spacing: 0.1em;
    padding: 0 10%;

    h1{
        margin: 0;
        color: $blue;
        font-weight: inherit;
        font-size: 2.5rem;
        font-family: 'Source Sans Pro';
        line-height: 1;
    }

    h3{
        margin: 15px 0 0;
        color: #d5d5d5;
        font-weight: inherit;
        font-size: 1.5rem;
        font-family: 'Source Sans Pro';
        line-height: 1;
    }

}
