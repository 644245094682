.error-message{
    z-index: 9;
    margin: 0 auto;
    text-align: center;
    pre{
        text-align: left;
        color: #fff;
        overflow: hidden;
    }
}
