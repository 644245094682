// https://github.com/modularscale/modularscale-sass
// golden ratio
$phi              : 1.618034;
$golden           : $phi;

$double-octave    : 4;
$major-twelfth    : 3;
$major-eleventh   : 2.666666667;
$major-tenth      : 2.5;
$octave           : 2;
$major-seventh    : 1.875;
$minor-seventh    : 1.777777778;
$major-sixth      : 1.666666667;
$minor-sixth      : 1.6;
$fifth            : 1.5;
$augmented-fourth : 1.41421;
$fourth           : 1.333333333;
$major-third      : 1.25;
$minor-third      : 1.2;
$major-second     : 1.125;
$minor-second     : 1.066666667;

// defaults
$base-size: 1rem;
$base-ratio: $minor-third;

// Exponential Number
// https://www.bhalash.com/archives/13544805657
// -----------------------------------------------------------------------------
// Raise a number to a given power
//
// @param   number      $number         Number to raise
// @param   number      $power          Power to which it should be raised
// @return  number      $exponent       Number raised to exponent
@function power($number, $power){

    $exponent: 1;

    @if $power > 0 {
        @for $i from 1 through $power {
            $exponent: $exponent * $number;
        }
    } @else if $power < 0 {
        @for $i from 1 through $power {
            $exponent: $exponent / $number;
        }
    }

    @return $exponent;

}

@function divide($a, $b){
    @return $a / $b;
}

@function multiply($a, $b){
    @return $a * $b;
}

@function ms($power: 0, $ratio: $base-ratio, $size: $base-size){

    $fun: multiply;

    @if $power < 0 {
        $fun: divide;
    }

    @return call($fun, $size, power($ratio, abs($power)));

}

@mixin ms($size: 0){
    font-size: ms($size);
}
